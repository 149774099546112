import { observer } from "mobx-react-lite"
import { FC, useContext } from "react"
import { Context } from ".."
import useWindowDimensions from "../core/hooks/useWindowDimensions"

const GlobalFader: FC = () => {
  const { store } = useContext(Context)
  const { width } = useWindowDimensions()

  return (
    <>
      <div 
        className="fader" 
        style={{
          display: store.isFaderVisible ? "flex" : "none", 
          opacity: store.isFaderShown ? "1" : "0"
        }}>
        <div className="fader_logo">
          <img style={{
            opacity: store.isFaderLogoVisible ? "1" : "0"
          }} src="/assets/images/logo.svg" />
          <div className="fader_x" style={{
            width: store.isFaderLogo ? (width > 960 ? "228px" : "160px") : "0px"
          }}>
            <span>X</span> <b>Владуе</b>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(GlobalFader)