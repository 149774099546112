import { FC, useContext, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import { Context } from '.';
import { StorageKeys } from './core/utils/defaults';
import { useLocation } from 'react-router-dom';
import { LOCALES } from './core/i18n/locales';
import { IntlProvider } from 'react-intl';

import useWindowDimensions from './core/hooks/useWindowDimensions';
import AppRouter from './components/AppRouter';

import './styles/App.scss';
import GlobalFader from './components/GlobalFader';

const App: FC = () => {

  const { store, adminStore } = useContext(Context)

  const location = useLocation()
  
  const [userName, setUserName] = useState("")
  const [userPhone, setUserPhone] = useState("")
  
  const { width, height } = useWindowDimensions()

  useEffect(() => {
    if (width <= 768) {
      store.setIsMobile(true)
    } else {
      store.setIsMobile(false)
    }
  }, [width, height])

  useEffect(() => {
    if (width <= 768) {
      store.setIsMobile(true);
    } else {
      store.setIsMobile(false);
    }
    
    if (location.pathname == "/") {
      store.setIsFaderShown(true)
      store.setIsFaderVisible(true)

      setTimeout(() => {
        store.setIsFaderLogoVisible(true)
        setTimeout(() => {
          store.setIsFaderLogo(true)
          setTimeout(() => {
            store.setIsFaderShown(false)
            setTimeout(() => {
              store.setIsFaderVisible(false)
            }, 400)
          }, 1000)
        }, 600)
      }, 300)
    }
  }, [])

  return (
    <>
      <GlobalFader />

      <AppRouter />
    </>
  )
}

export default observer(App)
