import React from "react";

const Home = React.lazy(() => import("../../pages/Home"))

export interface IRoute {
  path: string;
  component: React.ReactNode;
  exact?: boolean;
}

export enum RouteNames {
  HOME = '/'
}

export const publicRoutes: IRoute[ ] = [
  { path: RouteNames.HOME, exact: true, component: <Home /> }
]
